nav {
  position: absolute !important;
  width: 100%;
  top: 0;
}

footer {
  position: absolute !important;
  width: 100%;
  bottom: 0;
}

.nav-profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-profile-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;

  h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.user-img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-card-button-group button {
  margin-right: 5px;
  margin-bottom: 5px;
}

.page-container {
  min-height: 100vh;
  padding: 80px 0;
}

.login-container {
  color: #5c5c5c;
  background-color: #f9fafb;
  min-height: 100vh;
  padding: 50px 20px;
  margin: 0;
}

.login-inner {
  max-width: 600px;
  margin: auto;
}

.card-cover {
  overflow: hidden;
}

.card-inner {
  padding: 30px 20px;
}

.user-card-header {
  display: flex;

  img {
    height: 60px;
    width: auto;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.login-cover {
  text-align: center;

  .logo {
    //width: 100px;
    margin: auto;
    width: 100%;
    height: 150px;
    overflow: hidden;
    background-color: #614DFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo img {
    height: 100px;
    //width: 120px;
    width: auto;
  }
}

.download-app-cta {
  img {
    height: 60px;
    padding: 0;
  }
}

.download-app-cta-bg {
  margin: auto;

  img {
    height: 90px;
    padding: 0;
  }
}

.btn-primary {
  background-color: #614DFF;
}


.buy-item {
  display: flex;
  margin: auto;
}

.buy-item-logo {
  /* flex:1; */
  height: 65px;
}

.buy-item-logo img {
  height: 100%;
  border-radius: 8px;
  margin-right: 15px;
}

.buy-item-details {
  /* flex: 1; */
  //margin-left: 15px;
  text-align: left;
}

.buy-cta {
  margin-left: 20px;
  padding: 10px 20px;
}

.buy-cta {
  width: 100%;
  margin: 0;
}

.feature-card {
  max-width: 300px;
}

.inner-page {
  max-width: 960px;
  margin: auto;
}

.custom-row svg {
  height: 34px;
  width: auto;
  margin-right: 10px;
}

.border-primary{
  border-color: #614DFF !important;
}

.text-bg-primary{
  background-color: #614DFF !important;
}

.custom-bg-color{
  background-color: #6e5cfc !important;
}

.section{
  margin: 100px auto;

  @media (max-width: 768px) {
    margin: 100px auto;
  }
}